import './index.css';

function Skills() {
  return (
    <section className="skills text-gray-400 bg-gray-900 body-font">
    <div className="container px-5 py-24 mx-auto">
      <h1 className="sm:text-3xl text-2xl font-medium title-font text-center text-white mb-20">My skills
      </h1>
      <div className="flex flex-wrap sm:-m-4 -mx-4 -mb-10 mt-10 md:space-y-0 space-y-6">
        <div className="p-4 md:w-1/2 flex">
          <div
            className="w-12 h-12 inline-flex items-center justify-center rounded-full bg-gray-800 text-blue-400 mb-4 flex-shrink-0">
            <i className="fa-solid fa-code"></i>
          </div>
          <div className="flex-grow pl-6">
            <h2 className="text-white text-lg title-font font-medium mb-2">Web</h2>
            <p className="leading-relaxed text-base">ASP.NET 6+(C#), SignalR, EntityFrameworkCore, Dapper, Fiber(Go),
              Gin(Go), Angular, Vue, tailwindcss, A11y</p>
          </div>
        </div>
        <div className="p-4 md:w-1/2 flex">
          <div
            className="w-12 h-12 inline-flex items-center justify-center rounded-full bg-gray-800 text-blue-400 mb-4 flex-shrink-0">
            <i className="fa-solid fa-cloud"></i>
          </div>
          <div className="flex-grow pl-6">
            <h2 className="text-white text-lg title-font font-medium mb-2">Other</h2>
            <p className="leading-relaxed text-base">Kubernetes, Helm, Docker, Github Actions, Jenkins, KAMAL, Ubuntu,
              ElectronJS, WPF, SQL Server, Postgres, Redis, Kafka, RabbitMQ</p>
          </div>
        </div>
      </div>
      <div className="flex mt-10 justify-center">
        <div className="w-32 h-1 rounded-full bg-blue-500 inline-flex"></div>
      </div>
      <p className="mt-10 text-center xl:w-2/4 lg:w-3/4 mx-auto">
        For more details, please refer to my LinkedIn profile:&nbsp;
        <a href="https://www.linkedin.com/in/edwardhsu1994"
          className="bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded-full"><i
            className="fa-solid fa-add mr-1"></i>Edward Hsu</a>
      </p>
    </div>
  </section>
  );
}

export default Skills;