/* eslint-disable react/jsx-no-comment-textnodes */
import { NavLink } from "react-router-dom";

function AppHeader() {
    let navActiveClassNames= 'text-white border-b-2'
  return (
    <header className="text-gray-400 bg-gray-900 body-font">
        <div className="container mx-auto flex flex-wrap p-5 flex-col md:flex-row items-center">
        <a className="flex title-font font-medium items-center text-white mb-4 md:mb-0" href="/">
            <img src="/assets/images/favicon.png" alt="logo" className="w-10 h-10 text-white p-1 bg-blue-500 rounded-full"/>
            <span className="ml-3 text-xl font-bold">/* Life run on code */</span>
        </a>
        <nav className="md:ml-auto flex flex-wrap items-center text-base justify-center">
            <NavLink to="/" className={({isActive, isPending})=>{
                return `mr-5 hover:text-white ${isActive ? navActiveClassNames : ''}`;
            }}><i className="fa-solid fa-house"></i> Home</NavLink>
            <NavLink to="/about" className={({isActive, isPending})=>{
                return `mr-5 hover:text-white ${isActive ? navActiveClassNames : ''}`;
            }}><i className="fa-solid fa-address-card"></i> About</NavLink>
            <NavLink to="/articles" className={({isActive, isPending})=>{
                return `mr-5 hover:text-white ${isActive ? navActiveClassNames : ''}`;
            }}><i className="fa-solid fa-newspaper"></i> Articles</NavLink>
            <NavLink to="/favorites" className={({isActive, isPending})=>{
                return `mr-5 hover:text-white ${isActive ? navActiveClassNames : ''}`;
            }}><i className="fa-solid fa-heart"></i> Favorites</NavLink>
        </nav>
        </div>
    </header>
  );
}

export default AppHeader;
