import React from "react";
import Hero from "./components/hero";
import Skills from "./components/skills";
import Welcome from "./components/welcome";
import Timeline from "./components/timeline";

import { useLoadingContext } from "react-router-loading";

function Home() { 
  const loadingContext = useLoadingContext();

  document.title = "/* Life runs on code */";

  loadingContext.done();

  return (
    <main>
      <Hero />
      <Welcome/>
      <Skills/>
      <Timeline/>
    </main>
  );
}

export default Home;
