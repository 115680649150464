function Welcome() {
  return (
    <section className="text-gray-400 bg-gray-900 body-font">
      <div className="container px-5 py-24 mx-auto">
        <h1 className="sm:text-3xl text-2xl font-medium title-font text-center text-white mb-5">Welcome to my blog.</h1>
        <p className="text-base text-center leading-relaxed xl:w-2/4 lg:w-3/4 mx-auto text-gray-400 text-opacity-80">Here, I
          document the programming challenges I encounter and how I tackle them.</p>
      </div>
    </section>
  );
}

export default Welcome;