import { useEffect, useState } from "react";
import { useLoadingContext } from "react-router-loading";

declare type Library = {
    category: string;
    name: string;
    items: LibraryItem[];
}

declare type LibraryItem = {
    name: string;
    url: string;
    summary: string;
}

function FavoriteList() {
    const loadingContext = useLoadingContext();
    
     
    const [libraries, setLibraries] = useState<Library[]>([]);
 
    document.title = "Favorites :: /* Life runs on code */";

    useEffect(()=>{
        if(libraries.length > 0){
            return;
        }
        fetch('/assets/json/favorites.json').then(response => response.json()).then(data => {
            setLibraries(data); 
            loadingContext.done();
        });
    });
    

    return (
      <main>
        <section className="text-gray-400 bg-gray-900 body-font">
          <div className="container px-5 pt-24 mx-auto">
            <div className="flex flex-wrap w-full mb-20 flex-col items-center text-center">
              <h1 className="sm:text-3xl text-2xl font-medium title-font mb-2 text-white">
                A collection of awesome programming
              </h1>
              <p className="lg:w-1/2 w-full leading-relaxed text-opacity-80">
                Here is a list of tools that I find useful in programming.
              </p>
            </div>
          </div>
            

          <div className="bg-gray-800 pb-5 pt-5">
            {libraries.map((library, index) => {
                return (
                  <div className="container px-5  mx-auto items-center md:flex-row flex-col">
                    <div className="flex mt-10 justify-center">
                    <div className="w-full h-0.5 rounded-full bg-blue-500 inline-flex"></div>
                    </div>
                    <div className="flex flex-col md:pr-10 md:mb-0 mb-6 pr-0 w-full md:w-auto md:text-left text-center">
                      <h2 className="text-xs text-blue-400 tracking-widest font-medium title-font mb-1">
                        {library.category}
                      </h2>
                      <h1 className="md:text-3xl text-2xl font-medium title-font text-white">
                        {library.name}
                      </h1>
                    </div>
                    <div className="flex flex-wrap mb-5">
                        {library.items.map((item, index) => {
                            return (
                              <div className="xl:w-1/4 lg:w-1/2 md:w-full px-8 py-6 border-l-2 border-gray-800">
                                <h2 className="text-lg sm:text-xl text-white font-medium title-font mb-2">
                                  {item.name}
                                </h2>
                                <p className="leading-relaxed text-base mb-4">
                                  {item.summary}
                                </p>
                                <a className="text-blue-400 inline-flex items-center" href={item.url}>Learn More
                                    <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
                                        <path d="M5 12h14M12 5l7 7-7 7"></path>
                                    </svg>
                                </a>
                              </div>
                            );
                        })}
                    </div>
                  </div>
                ); 
            })}
          </div>
          
        </section> 
      </main>
    );
}

export default FavoriteList;