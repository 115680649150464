import { Route } from "react-router-loading";
import ArticleList from "./pages/articlesList";
import { Navigate } from "react-router-dom";
import Article from "./pages/article";

function ArticlesRoutes() {
  return (
    <Route path="articles" loading>
        <Route path="" element={<Navigate to={"list"} />} loading/>
        <Route path="list" loading>
            <Route path="" element={<Navigate to={"pages/1"} />} loading/>
            <Route path="pages/:pageNo" element={<ArticleList />} loading/>
            <Route path="*" element={<Navigate to={"pages/1"} />} loading/>
        </Route>
        <Route path="post/:postId"  element={<Article />} loading/>
    </Route>
  );
}

export default ArticlesRoutes;
