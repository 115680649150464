import React from 'react';
import './index.css';

function Hero() {
  return (
    <section className="hero text-gray-400 bg-gray-900 body-font">
      <div className="container mx-auto flex px-5 py-20 items-center justify-center flex-col">
        <img className="lg:w-1/6 md:w-2/6 sm:w-2/6 w-2/6 mb-10 object-cover object-center rounded-full" alt="hero" src="/assets/images/avatar_life.png" />
        <div className="text-center lg:w-2/3 w-full">
          <h1 className="title-font sm:text-4xl text-3xl mb-4 font-medium text-white">Hi! I'm Edward Hsu</h1>
          <p className="leading-relaxed mb-1">Software Engineer</p>
          <p className="leading-relaxed mb-8"><i className="fa-solid fa-location-dot"></i> Taipei, Taiwan</p>
        </div>
      </div>
    </section>
  );
}

export default Hero;