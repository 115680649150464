import { useEffect, useState } from "react";

type PageNavProps = {
  totalPages: number;
  currentPage: number;
  onPageChanged?: (pageNo: number) => void;
};

declare type PageStatus = {
  pageNo: number;
  active: boolean;
};

function PageNav(props: PageNavProps) {
  const [pageStatus, setPageStatus] = useState<PageStatus[]>([]);
  const [prePageNo, setPrePageNo] = useState<number>(0);
  const [nextPageNo, setNextPageNo] = useState<number>(0);
  const [visiblePages, setVisiblePages] = useState<PageStatus[]>([]);

  useEffect(() => {
    let tempPageStatus: PageStatus[] = [];
    for (let i = 0; i < props.totalPages; i++) {
      let pageNo = i + 1;
      let active = false;
      if (pageNo === props.currentPage) {
        active = true;
      }
      tempPageStatus.push({ pageNo: pageNo, active: active });
    }
    setPageStatus(tempPageStatus);

    if (props.currentPage > 1) {
      setPrePageNo(props.currentPage - 1);
    } else {
      setPrePageNo(0);
    }

    if (props.currentPage < props.totalPages) {
      setNextPageNo(props.currentPage + 1);
    } else {
      setNextPageNo(0);
    }

    setVisiblePages(getVisiblePages());
  });

  const prePage = (e: React.MouseEvent<HTMLAnchorElement>) => {
    if (prePageNo < 1) {
      return;
    }

    if (props.onPageChanged) {
      props.onPageChanged(props.currentPage - 1);
    }
  };

  const nextPage = (e: React.MouseEvent<HTMLAnchorElement>) => {
    if (nextPageNo < 1) {
      return;
    }

    if (props.onPageChanged) {
      props.onPageChanged(props.currentPage + 1);
    }
  };

  const getVisiblePages = () => {
    let visiblePages: PageStatus[] = [];

    if(props.currentPage <= 3){
        for(let i = 0; i < 5 && i < pageStatus.length; i++){
            visiblePages.push(pageStatus[i]);
        }
        return visiblePages;
    }

    if(props.currentPage >= props.totalPages - 2){
        for(let i = Math.max(props.totalPages - 5,0); i < props.totalPages; i++){
            visiblePages.push(pageStatus[i]);
        }
        return visiblePages;
    }

    for(let i = props.currentPage - 3; i < props.currentPage + 2; i++){
        visiblePages.push(pageStatus[i]);
    } 

    return visiblePages;
  }



  return (
    <nav aria-label="Page navigation" className="container py-5 mx-auto">
      <ul className="flex items-center -space-x-px h-8 text-sm justify-center">
        <li>
          <a
            href="#"
            onClick={prePage}
            className="flex items-center justify-center px-3 h-8 ms-0 leading-tight text-gray-500 bg-white border border-e-0 border-gray-300 rounded-s-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
          >
            <span className="sr-only">Previous</span>
            <svg
              className="w-2.5 h-2.5 rtl:rotate-180"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 6 10"
            >
              <path
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M5 1 1 5l4 4"
              />
            </svg>
          </a>
        </li>

        {
            visiblePages.map((x)=>{
                return  (
                    <li>
                        <a
                            href="#"
                            onClick={(e) => {
                                if (props.onPageChanged) {
                                    props.onPageChanged(x.pageNo);
                                }
                            }}
                            aria-current={x.active ? "page" : undefined}
                            className={x.active ?"z-10 flex items-center justify-center px-3 h-8 leading-tight text-blue-600 border border-blue-300 bg-blue-50 hover:bg-blue-100 hover:text-blue-700 dark:border-gray-700 dark:bg-gray-700 dark:text-white" : "flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"}
                        >
                            {x.pageNo}
                        </a>
                    </li>
                );
            })
        }

        <li>
          <a
            href="#"
            onClick={nextPage}
            className="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 rounded-e-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
          >
            <span className="sr-only">Next</span>
            <svg
              className="w-2.5 h-2.5 rtl:rotate-180"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 6 10"
            >
              <path
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="m1 9 4-4-4-4"
              />
            </svg>
          </a>
        </li>
      </ul>
    </nav>
  );
}

export default PageNav;
