import { useLoadingContext } from "react-router-loading";

function About(){
    const loadingContext = useLoadingContext();

    document.title = "About :: /* Life runs on code */";

    loadingContext.done();
    return (
      <main>
        <section className="text-gray-400 bg-gray-900 body-font">
          <div className="container px-5 py-24 mx-auto flex flex-col">
            <div className="lg:w-4/6 mx-auto">
              <div className="rounded-lg h-64 overflow-hidden">
                <img
                  alt="banner"
                  className="object-cover object-center h-full w-full"
                  src="/assets/images/background_01.jpg"
                />
              </div>
              <div className="flex flex-col sm:flex-row mt-10">
                <div className="sm:w-1/3 text-center sm:pr-8 sm:py-8">
                  <div className="w-50 h-50 rounded-full inline-flex items-center justify-center bg-gray-800 text-gray-600">
                    <img
                      className="rounded-full"
                      src="/assets/images/avatar.jpeg"
                      alt="avatar"
                    />
                  </div>
                  <div className="flex flex-col items-center text-center justify-center">
                    <h2 className="font-medium title-font mt-4 text-white text-lg">
                      徐培堯 (Edward Hsu)
                    </h2>
                    <div className="w-12 h-1 bg-blue-500 rounded mt-2 mb-4"></div>
                    <p className="text-base text-gray-400">Software Engineer</p>
                  </div>
                </div>
                <div className="sm:w-2/3 sm:pl-8 sm:py-8 sm:border-l border-gray-800 sm:border-t-0 border-t mt-4 pt-4 sm:mt-0 text-center sm:text-left">
                  <div className="mb-5 flex flex-wrap text-sm font-medium text-center text-gray-500 dark:text-gray-400">
                    <input
                      id="tab-chinese"
                      type="radio"
                      name="tabs"
                      className="peer/tab-chinese opacity-0 absolute"
                      defaultChecked={true}
                    />
                    <label
                      htmlFor="tab-chinese"
                      className="cursor-pointer mr-1 mb-2 inline-block px-4 py-3 rounded-lg hover:text-gray-900 hover:bg-gray-100 dark:hover:bg-gray-800 dark:hover:text-white peer-checked/tab-chinese:bg-blue-600 peer-checked/tab-chinese:text-white"
                    >
                      中文
                    </label>
                    <input
                      id="tab-english"
                      type="radio"
                      name="tabs"
                      className="peer/tab-english opacity-0 absolute"
                    />
                    <label
                      htmlFor="tab-english"
                      className="cursor-pointer mr-1 mb-2 inline-block px-4 py-3 rounded-lg hover:text-gray-900 hover:bg-gray-100 dark:hover:bg-gray-800 dark:hover:text-white peer-checked/tab-english:bg-blue-600 peer-checked/tab-english:text-white"
                    >
                      English
                    </label>
                    <p className="leading-relaxed text-lg text-left mb-4 hidden peer-checked/tab-chinese:block">
                      我對程式開發抱有深深的熱情，不斷精進自我，不論是在每個專案還是個人作品中，我都努力提升自己的專業知識和技術水平。作為一名來自台南的工程師，我畢業於國立高雄第一科技大學，起初在高雄工作，如今轉戰台北。我的主要工作包括後端程式開發、資料庫規劃和維護，同時與前端團隊密切合作。除了工作，我也熱衷於關注GitHub和其他社群中的最新技術動態，並與同行交流。在休閒時光，我喜歡和朋友一起射箭或者到處逛街。
                    </p>
                    <p className="leading-relaxed text-lg text-left mb-4 hidden peer-checked/tab-english:block">
                      I have a deep interest in software development, constantly
                      striving to improve myself in every project and endeavor,
                      enhancing my professional knowledge and honing my skills.
                      Hailing from Tainan, I graduated from National Kaohsiung
                      First University of Science and Technology, initially
                      working in Kaohsiung before transitioning to Taipei. My
                      main responsibilities involve backend development,
                      database planning, and maintenance, collaborating closely
                      with frontend developers. Apart from work, I actively
                      follow the latest technological advancements and engage in
                      technical discussions on GitHub and other communities.
                      During leisure time, I enjoy archery with friends or
                      exploring various places.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    );
}

export default About;