import './index.css';

function Timeline() {
  return (
    <section className="timeline text-gray-400 bg-gray-900 body-font">
      <div className="container mx-auto flex px-5 py-20 items-center justify-center flex-col" >
        <h1 className="sm:text-3xl text-2xl font-medium title-font text-center text-white mb-20">My career
        </h1>

        <div className="relative pl-8 sm:pl-32 py-6 group w-full">
          <div className="font-caveat font-medium text-2xl text-blue-500 mb-1 sm:mb-0 text-left">Engineer</div>
          <div
            className="flex flex-col sm:flex-row items-start mb-1 group-last:before:hidden before:absolute before:left-2 sm:before:left-0 before:h-full before:px-px before:bg-slate-300 sm:before:ml-[6.5rem] before:self-start before:-translate-x-1/2 before:translate-y-3 after:absolute after:left-2 sm:after:left-0 after:w-2 after:h-2 after:bg-blue-600 after:border-4 after:box-content after:border-slate-50 after:rounded-full sm:after:ml-[6.5rem] after:-translate-x-1/2 after:translate-y-1.5">
            <time
              className="sm:absolute left-0 translate-y-0.5 inline-flex items-center justify-center text-xs font-semibold uppercase w-20 h-6 mb-3 sm:mb-0 text-emerald-600 bg-emerald-100 rounded-full">2022/7-NOW</time>
            <div className="text-xl font-bold">Trend Micro Incorporated</div>
          </div>
          <div className="text-slate-500 text-left">PLS</div>
        </div>

        <div className="relative pl-8 sm:pl-32 py-6 group w-full">
          <div className="font-caveat font-medium text-2xl text-blue-500 mb-1 sm:mb-0 text-left">Engineer(Contract)</div>
          <div
            className="flex flex-col sm:flex-row items-start mb-1 group-last:before:hidden before:absolute before:left-2 sm:before:left-0 before:h-full before:px-px before:bg-slate-300 sm:before:ml-[6.5rem] before:self-start before:-translate-x-1/2 before:translate-y-3 after:absolute after:left-2 sm:after:left-0 after:w-2 after:h-2 after:bg-blue-600 after:border-4 after:box-content after:border-slate-50 after:rounded-full sm:after:ml-[6.5rem] after:-translate-x-1/2 after:translate-y-1.5">
            <time
              className="sm:absolute left-0 translate-y-0.5 inline-flex items-center justify-center text-xs font-semibold uppercase w-20 h-6 mb-3 sm:mb-0 text-emerald-600 bg-emerald-100 rounded-full">2021-2022/7</time>
            <div className="text-xl font-bold">Trend Micro Incorporated</div>
          </div>
          <div className="text-slate-500 text-left">PLS</div>
        </div>

        <div className="relative pl-8 sm:pl-32 py-6 group w-full">
          <div className="font-caveat font-medium text-2xl text-blue-500 mb-1 sm:mb-0 text-left">Senior Engineer</div>
          <div
            className="flex flex-col sm:flex-row items-start mb-1 group-last:before:hidden before:absolute before:left-2 sm:before:left-0 before:h-full before:px-px before:bg-slate-300 sm:before:ml-[6.5rem] before:self-start before:-translate-x-1/2 before:translate-y-3 after:absolute after:left-2 sm:after:left-0 after:w-2 after:h-2 after:bg-blue-600 after:border-4 after:box-content after:border-slate-50 after:rounded-full sm:after:ml-[6.5rem] after:-translate-x-1/2 after:translate-y-1.5">
            <time
              className="sm:absolute left-0 translate-y-0.5 inline-flex items-center justify-center text-xs font-semibold uppercase w-20 h-6 mb-3 sm:mb-0 text-emerald-600 bg-emerald-100 rounded-full">2017/E-2020</time>
            <div className="text-xl font-bold">Hamastar Technology CO., LTD.</div>
          </div>
          <div className="text-slate-500 text-left">Product Department</div>
        </div>

        <div className="relative pl-8 sm:pl-32 py-6 group w-full">
          <div className="font-caveat font-medium text-2xl text-blue-500 mb-1 sm:mb-0 text-left">Assistant(Contract)</div>
          <div
            className="flex flex-col sm:flex-row items-start mb-1 group-last:before:hidden before:absolute before:left-2 sm:before:left-0 before:h-full before:px-px before:bg-slate-300 sm:before:ml-[6.5rem] before:self-start before:-translate-x-1/2 before:translate-y-3 after:absolute after:left-2 sm:after:left-0 after:w-2 after:h-2 after:bg-blue-600 after:border-4 after:box-content after:border-slate-50 after:rounded-full sm:after:ml-[6.5rem] after:-translate-x-1/2 after:translate-y-1.5">
            <time
              className="sm:absolute left-0 translate-y-0.5 inline-flex items-center justify-center text-xs font-semibold uppercase w-20 h-6 mb-3 sm:mb-0 text-emerald-600 bg-emerald-100 rounded-full">2017</time>
            <div className="text-xl font-bold">National Kaohsiung First University of Science and Technology</div>
          </div>
          <div className="text-slate-500 text-left">Library and Information Center / System Team</div>
        </div>

        <div className="relative pl-8 sm:pl-32 py-6 group w-full">
          <div className="font-caveat font-medium text-2xl text-blue-500 mb-1 sm:mb-0 text-left">Student</div>
          <div
            className="flex flex-col sm:flex-row items-start mb-1 group-last:before:hidden before:absolute before:left-2 sm:before:left-0 before:h-full before:px-px before:bg-slate-300 sm:before:ml-[6.5rem] before:self-start before:-translate-x-1/2 before:translate-y-3 after:absolute after:left-2 sm:after:left-0 after:w-2 after:h-2 after:bg-blue-600 after:border-4 after:box-content after:border-slate-50 after:rounded-full sm:after:ml-[6.5rem] after:-translate-x-1/2 after:translate-y-1.5">
            <time
              className="sm:absolute left-0 translate-y-0.5 inline-flex items-center justify-center text-xs font-semibold uppercase w-20 h-6 mb-3 sm:mb-0 text-emerald-600 bg-emerald-100 rounded-full">2012-2016</time>
            <div className="text-xl font-bold">National Kaohsiung First University of Science and Technology</div>
          </div>
          <div className="text-slate-500 text-left">Department of Information Management</div>
        </div>
      </div>

    </section>
  );
}

export default Timeline;