import { useEffect, useState } from "react";
import { NavLink, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useLoadingContext } from "react-router-loading";
import PageNav from "../../../../components/pageNav";

declare type ArticleItem = {
    id: string; 
    date: string;
    category: string;
    zh_title: string;
    zh_summary: string;
    en_title: string;
    en_summary: string;
}

function ArticleList(){
    const loadingContext = useLoadingContext(); 
    const [searchParams, setSearchParams] = useSearchParams();
    let {pageNo} = useParams<{pageNo: string}>(); 
    const [pageNoNum, setPageNoNum] = useState<number>(1);
    const [articles, setArticles] = useState<ArticleItem[]>([]);
    const [lang, setLang] = useState<string>("en-US"); 
    const navigate = useNavigate();

    const [lastLoadedPageNo, setLastLoadedPageNo] = useState<number>(0);

    const loadArticles = async() => {
      let pageNoStr = pageNo as string; 
      let pageNoNum = parseInt(pageNoStr);
      
      if (pageNoNum === lastLoadedPageNo) {
        return;
      }

      loadingContext.start();
      setPageNoNum(pageNoNum);
      let response = await fetch(`/assets/json/articles-list/articles-list-${pageNoStr}.json`);
      let data = await response.json();
      
      setLastLoadedPageNo(pageNoNum);

      setArticles(data);
      loadingContext.done(); 
    }

    useEffect(() => {
      if (searchParams.get("lang") === null) {
        searchParams.set("lang", "en-US");
        setSearchParams(searchParams);
      }else{ 
        setLang(searchParams.get("lang") as string);
      }
      document.title = "Articles :: /* Life runs on code */";
      loadingContext.done(); 
      loadArticles();
    }, [loadArticles, loadingContext, searchParams, setSearchParams]);
 
    const onPageChanged = (pageNo: number) => {
      navigate(`/articles/list/pages/${pageNo}?lang=${lang}`);
    }

    const changeLang = (e: React.ChangeEvent<HTMLSelectElement>) => {
      searchParams.set("lang", e.target.value);
      setSearchParams(searchParams);
    }

    let articleList = articles.map((article, index) => {
        let date = article.date.split('-');
        return (
          <div className="py-8 px-4 lg:w-1/2">
            <div className="h-full flex items-start">
              <div className="w-12 flex-shrink-0 flex flex-col text-center leading-none">
                <span className="text-gray-400 pb-2 mb-2 border-b-2 border-gray-700">
                  {date[0]}
                </span>
                <span className="font-medium text-lg leading-none text-gray-300 title-font">
                  {date[1]}
                  {date[2]}
                </span>
              </div>
              <div className="w-full flex-grow pl-6">
                <h2 className="tracking-widest text-xs title-font font-medium text-blue-400 mb-1">
                  {article.category}
                </h2>
                <h1 className="title-font text-xl font-medium text-white mb-3 max-w-full line-clamp-2">
                  {lang === "en-US" ? article.en_title : article.zh_title}
                </h1>
                <div className="  leading-relaxed mb-5 line-clamp-3 max-w-full whitespace-normal">
                  {lang === "en-US" ? article.en_summary : article.zh_summary}
                </div>
                <NavLink to={"../../post/" + article.id + "?lang=" + lang} className="text-blue-400 inline-flex items-center hover:text-blue-100">
                    Learn More
                    <svg
                        className="w-4 h-4 ml-2"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        stroke-width="2"
                        fill="none"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    >
                    <path d="M5 12h14"></path>
                    <path d="M12 5l7 7-7 7"></path>
                    </svg>
                </NavLink>
              </div>
              
            </div>
          </div>
        );
    });

    return (
      <main>
        <section className="text-gray-400 bg-gray-900 body-font">
          <div className="container px-5 pt-24 mx-auto">
            <div className="flex flex-wrap w-full mb-20 flex-col items-center text-center">
              <h1 className="sm:text-3xl text-2xl font-medium title-font mb-2 text-white">
                Articles
              </h1>
              <p className="lg:w-1/2 w-full leading-relaxed text-opacity-80">
                Here I document the programming problems I encounter and my
                programming learning journey.
              </p>
            </div>
          </div>
          <div className="mb-5 flex -mt-20  items-center justify-end lg:mx-20 mx-5 sm:mx-0 ">
            <label htmlFor="language" className="text-sm font-medium text-gray-900 dark:text-white mr-1">Select language: </label>
            <select id="language" onChange={changeLang} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500   p-1.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
              <option value="en-US" selected={lang==="en-US"? true: undefined}>English(ChatGPT)</option>
              <option value="zh-TW" selected={lang==="zh-TW"? true: undefined}>中文</option> 
            </select>
          </div>

          <div className="bg-gray-800 pb-5 pt-5">
            <div className="lg:mx-20 mx-5 sm:mx-0 flex flex-wrap ">
              {articleList}
            </div>
            <PageNav totalPages={9} currentPage={pageNoNum} onPageChanged={onPageChanged}></PageNav>
          </div>
        </section>
      </main>
    );
}

export default ArticleList;