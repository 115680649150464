 

import React from 'react'; 
import './App.css';
import AppHeader from './components/AppHeader';
import AppFooter from './components/AppFooter';
import { Navigate } from 'react-router-dom';
import { Routes, Route } from "react-router-loading";
import Home from './pages/home';
import About from './pages/about';
import NotFound from './pages/notfound'; 
import FavoriteList from './pages/favorites'; 
import ArticlesRoutes from './pages/articles';



function App() {
  const [goToTopHidden, setGoToTopHidden] = React.useState(true);
  const handleScroll = () => {
    if (window.scrollY > 100) {
      setGoToTopHidden(false);
    } else {
      setGoToTopHidden(true);
    }
  };

  React.useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const goToTop = () => {
    window.scrollTo(0, 0);
  };

  return (
    <div className="App">
      <AppHeader />
      <Routes>
        <Route path="/" element={<Home />} loading/> 
        <Route path="home" element={<Navigate to={"/"} />} loading/>
        <Route path="about" element={<About/>} loading/>
        {ArticlesRoutes()} 

        {/* Compatible old blog routing */}
        <Route path="article/*" element={<Navigate to={`/articles${window.location.pathname.substring('/article'.length)}`}></Navigate>} loading/>

        <Route path="favorites" element={<FavoriteList/>} loading/>
        <Route path="*" element={<NotFound/>} loading/>
      </Routes>
      <AppFooter />
      <button id="to-top-button" title="Go To Top"
        onClick={goToTop}
        className={"fixed z-50 bottom-10 right-10 p-4 border-0 w-14 h-14 rounded-full shadow-md bg-blue-600 hover:bg-blue-700 text-white text-lg font-semibold transition-colors duration-300 " + (goToTopHidden ? "hidden" : "")  }>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
            <path d="M12 4l8 8h-6v8h-4v-8H4l8-8z" />
        </svg>
        <span className="sr-only">Go to top</span>
    </button>
    </div>
  );
}

export default App;
