function AppFooter() {
    return (
        <footer className="text-gray-400 bg-gray-900 body-font">
        <div className="container px-5 py-8 mx-auto flex items-center sm:flex-row flex-col">
          <span className="flex title-font font-medium items-center md:justify-start justify-center text-white">
             <span className="ml-3 text-sm">© 2024 Edward Hsu</span>
          </span>
          <span className="inline-flex sm:ml-auto sm:mt-0 mt-4 justify-center sm:justify-start">
            <a className="text-gray-400" href="https://github.com/XuPeiYao">
              <i className="fa-brands fa-github"></i>
            </a>
            <a className="ml-3 text-gray-400" href="https://www.linkedin.com/in/edwardhsu1994/">
              <i className="fa-brands fa-linkedin"></i>
            </a>
            <a className="ml-3 text-gray-400" href="mailto://st89382000@gmail.com">
              <i className="fa-solid fa-envelope"></i>
            </a>
          </span>
          <span className="ml-5">v3.0.12</span>
        </div>
      </footer>
    );
  }
  
  export default AppFooter;
  