import React, { useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useLoadingContext } from "react-router-loading";
import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm'; 
import {atomOneDark} from 'react-syntax-highlighter/dist/esm/styles/hljs'
import SyntaxHighlighter from "react-syntax-highlighter";
declare type ArticleFull = {
  id: string;
  date: string;
  category: string;
  zh_title: string;
  zh_summary: string;
  zh_content: string;
  en_title: string;
  en_summary: string;
  en_content: string;
};

function Article() {
  const [searchParams, setSearchParams] = useSearchParams();
  let { postId } = useParams<{ postId: string }>();
  const [lang, setLang] = useState<string>("en-US");
  const [article, setArticle] = useState<ArticleFull>({} as ArticleFull);
  const loadingContext = useLoadingContext(); 
    const [loaded, setLoaded] = useState<boolean>(false); 
  const loadArticle = async () => {
    if (loaded) {
      return;
    }
    loadingContext.start();
    let response = await fetch(`/assets/json/articles/${postId}.json`);
    let data = (await response.json()) as ArticleFull;
    setLoaded(true);
    setArticle(data);
    document.title =
      (lang === "en-US" ? data.en_title : data.zh_title) +
      " :: /* Life runs on code */";

    loadingContext.done(); 
  };

  useEffect(() => {
    if (searchParams.get("lang") === null) {
      searchParams.set("lang", "en-US");
      setSearchParams(searchParams);
      setLang("en-US"); 
    } else {
      setLang(searchParams.get("lang") as string);
    }
    
    loadArticle();
  }, [loadArticle, searchParams, setSearchParams]);

  const changeLang = (e: React.ChangeEvent<HTMLSelectElement>) => {
    searchParams.set("lang", e.target.value);
    setSearchParams(searchParams);
    setLang(e.target.value); 
  };
  return (
    <main>
      <section className="text-gray-400 bg-gray-900 body-font">
        <div className="flex  items-center justify-end lg:mx-20 mx-5 sm:mx-0 sm:mb-s md:mb-6 ">
          <label
            htmlFor="language"
            className="text-sm font-medium text-gray-900 dark:text-white mr-1"
          >
            Select language:{" "}
          </label>
          <select
            id="language"
            onChange={changeLang}
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500   p-1.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            value={lang}
          >
            <option
              value="en-US" 
            >
              English(ChatGPT)
            </option>
            <option
              value="zh-TW" 
            >
              中文
            </option>
          </select>
        </div>
        <div className="container  px-5 -smt-6 pt-6 mx-auto sm:mt- md:-mt-16">
          <div className="flex flex-col md:pr-10 md:mb-0 mb-6 pr-0 w-full md:w-auto md:text-left text-center">
            <h2 className="text-xs text-blue-400 tracking-widest font-medium title-font mb-1">
              {article.category}
            </h2>
            <h1 className="md:text-3xl text-2xl font-medium title-font text-white mb-4">
              {lang === "en-US" ? article.en_title : article.zh_title}
            </h1>
          </div>
        </div>

        <div className="bg-gray-800 py-5">
            <div className="relative w-full px-6 py-12 bg-white shadow-xl shadow-slate-700/10 ring-1 ring-gray-900/5 md:max-w-3xl md:mx-auto lg:max-w-4xl lg:pt-16 lg:pb-28">
                <article className="prose prose-zinc m-auto text-left" style={{maxWidth:"100%"}}>
                    <Markdown remarkPlugins={[remarkGfm]} children={lang === "en-US" ? article.en_content : article.zh_content} components={{
                        code(props) {
                            const {children, className, node, ...rest} = props
                            const match = /language-(\w+)/.exec(className || '')
                            return match ? (
                                <SyntaxHighlighter
                                    children={String(children).replace(/\n$/, '')}
                                    language={match[1]}
                                    style={atomOneDark}
                                />
                            ) as any : (
                            <code {...rest} className={className}>
                                {children}
                            </code>
                            )
                        }
                        }}/>
                </article > 
 
            </div>
        </div>
      </section> 
    </main>
  );
}

export default Article;
